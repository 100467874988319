import React, { Component } from "react";
import { withVueRouter, applyVueInReact, connectVuex } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";
import ShowBank from "@/components/ShowBank";
import PinConfirm from "@/components/PinConfirm";
import axios from "@/axios";
import api from "../../helpers/api";
import { validateNumberWithService } from "../../helpers/general";

class DataPack extends Component {
  ShowBankComp = applyVueInReact(ShowBank);
  PinConfirmComp = applyVueInReact(PinConfirm);
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      message: "",
      telecom: "",
      paymentModel: false,
      message: false,
      dataPack: [],
      selectedPack: "",
      phoneNumber: "",
      banks: "",
      pinVerified: false,
      validPhoneNumber: false,
      topupServices: "",
      pin: "",
    };
  }
  componentDidMount() {
    if (
      this.props.$vueRoute.params.telecom !== "ntc" &&
      this.props.$vueRoute.params.telecom !== "ncell"
    ) {
      this.props.$vueRouter.push("/");
    } else {
      const loadBankAndService = async () => {
        this.setState({ banks: await api.banks() });
        this.setState({
          topupServices: await api.servicesByCategorySlug("MOBILE_TOPUP"),
        });
      };
      loadBankAndService();

      this.setState(
        {
          telecom: this.props.$vueRoute.params.telecom,
        },
        () => {
          axios
            .get("/api/v2/services/telecom/servicepackages", {
              params: {
                service:
                  this.state.telecom === "ntc" ? "NepalTelecom" : "Ncell",
              },
            })
            .then((data) =>
              this.setState({
                dataPack: data.data.response.Packages,
                loading: false,
              })
            );
        }
      );
    }
  }

  handlePackageChange = (event) => {
    const data = event.target.value ? JSON.parse(event.target.value) : "";
    this.setState({ selectedPack: data });
  };
  handleNumberChange = (event) => {
    if (/^$|^[0-9]+$/.test(event.target.value)) {
      this.setState({ phoneNumber: event.target.value }, () => {
        if (this.state.phoneNumber.length === 10) {
          const validNumber = validateNumberWithService(
            this.state.phoneNumber,
            this.state.topupServices
          );
          // service company is according to the name in services database.
          if (!validNumber) {
            this.setState({
              validPhoneNumber: false,
              message: "This number is not supported yet.",
            });
          } else if (
            (this.state.telecom === "ncell" &&
              validNumber.service.serviceCompany === "Ncell") ||
            (this.state.telecom === "ntc" &&
              validNumber.service.serviceCompany === "Nepal Telecom")
          ) {
            this.setState({ validPhoneNumber: true, message: "" });
          } else {
            this.setState({
              validPhoneNumber: false,
              message: "Invalid Phone Number.",
            });
          }
        }
      });
    }
  };

  makePayment = () => {
    if (this.state.pinVerified) {
      this.setState({
        loading: true,
        paymentModel: false,
        pinVerified: false,
        message: "",
      });
      axios
        .post("api/v4/mobile_topup/payment", {
          amount: this.state.selectedPack.Amount,
          number: this.state.phoneNumber,
          package: this.state.selectedPack.Code,
          service:
            this.state.telecom === "ncell"
              ? "NCELL_PACKAGE"
              : "NEPAL_TELECOM_PACKAGE",
          pin: this.state.pin,
        })
        .then((res) => {
          this.setState({ loading: false });
          if (res.data) {
            if (res.data.data.response[0]) {
              if (
                res.data.data.response[0].Code === "001" ||
                res.data.data.response[0].Code === "028" ||
                res.data.data.response[0].Code === "011"
              ) {
                this.setState({ message: "Invalid Number" });
              } else {
                this.setState({ message: res.data.data.response[0].Message });
              }
            }
            if (res.data.status === 1) {
              this.props.vuexDispatch("UPDATE_BALANCE");
            }
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            pinVerified: false,
            paymentModel: false,
            message: "Something went wrong.",
          });
        });
    } else {
      this.setState({ paymentModel: true });
    }
  };

  bankClicked = (bank) => {
    this.setState({ bank });
  };
  pinVerified(cb, verified) {
    if (verified) {
      this.setState({ pinVerified: true, pin: verified }, () => {
        cb(verified);
      });
    } else {
      this.setState({ pinVerified: false, paymentModel: false });
    }
  }

  verifyPin = (verified) => {
    this.pinVerified(this.makePayment, verified);
  };

  previewDetails = () => {
    return {
      "Phone Number": this.state.phoneNumber,
      "Total Amount to Pay": "Rs. " + this.state.selectedPack.Amount,
    };
  };

  handlePaymentSubmit = (event) => {
    event.preventDefault();
    if (!this.state.selectedPack) {
      this.setState({ message: "Please select the data package." });
      return;
    }
    this.makePayment();
  };

  render() {
    return (
      <div className="container">
        {this.state.paymentModel && (
          <this.PinConfirmComp
            previewDetails={this.previewDetails()}
            pinVerification={this.verifyPin}
          />
        )}
        <form onSubmit={this.handlePaymentSubmit}>
          <div className="wallet-form-box card">
            <div className="card-body">
              <a href="/" className="btn btn-close">
                <i className="fa fa-times"></i>
              </a>
              <div className="row">
                <div className="col-sm-9 col-lg-6">
                  <h4> {this.state.telecom.toUpperCase()} Data Pack</h4>
                  {this.state.message && (
                    <div className="form-group">
                      <div className="show-message">{this.state.message}</div>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">+977</div>
                      </div>
                      <input
                        className="form-control"
                        type="tel"
                        name="number"
                        pattern="[0-9]+"
                        autoComplete="off"
                        maxLength={10}
                        required
                        disabled={this.state.loading}
                        value={this.state.phoneNumber}
                        onChange={this.handleNumberChange}
                      />
                    </div>
                  </div>
                  {/* DATA PACKAGE DIVISION */}
                  <div className="form-group">
                    <select
                      className="custom-select"
                      name="data pack"
                      onChange={this.handlePackageChange}
                    >
                      <option value="">-- SELECT PACKAGE --</option>
                      {this.state.dataPack.map((data) => {
                        return (
                          <option key={data.Code} value={JSON.stringify(data)}>
                            {data.Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* PACKAGE INFORMATION */}

                  {this.state.selectedPack && (
                    <div>
                      {this.state.selectedPack.Description && (
                        <div className="mt-4">
                          <div className="font-weight-bold">
                            Package Description
                          </div>
                          <div>{this.state.selectedPack.Description}</div>
                        </div>
                      )}
                      <div className="mt-2">
                        <div className="mt-2">
                          <div className="font-weight-bold">Package Amount</div>
                          <div>Rs. {this.state.selectedPack.Amount}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.validPhoneNumber && this.state.selectedPack && (
                    <this.ShowBankComp
                      bankClicked={this.bankClicked}
                      banks={this.state.banks}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        <this.LoadingSpinnerComp loading={this.state.loading} />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    vuexDispatch: (actionName) => {
      return dispatch(actionName);
    },
  };
};
export default connectVuex({ mapDispatchToProps })(withVueRouter(DataPack));
